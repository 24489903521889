<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link :to="`/accounts/${$route.params.accountId}`" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon> Member profile
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="d-flex justify-space-between align-center">
          <div class="text-uppercase text-h5 font-weight-bold">general</div>
          <v-btn :to="`/users/${data.user.id}`" text color="input">User Profile <v-icon right>mdi-open-in-new</v-icon></v-btn>
        </div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="name" label="Name" filled outlined dense readonly color="input"></v-text-field>
              <v-text-field
                v-model="data.user.email"
                label="Email"
                filled
                outlined
                dense
                readonly
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field v-model="data.status" label="Status" filled outlined dense readonly color="input"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-16">
              <v-select
                v-model="data.role"
                label="Role"
                :items="roleList"
                item-text="title"
                item-value="name"
                filled
                outlined
                dense
                required
                :readonly="!data.can_edit"
                color="input"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <div class="d-flex justify-sm-space-between justify-center">
            <v-btn v-if="data.can_remove" @click="modal = true" outlined elevation="0" width="184" height="48" color="error">Remove</v-btn>
            <v-btn v-if="data.can_edit" @click="upadate" class="ml-sm-auto ml-3" elevation="0" width="184" height="48" color="primary">
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="modal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Remove the member profile</div>
          <div class="mt-3 opasity--text">Do you really want to remove the member profile {{ name }}</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="modal = false">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="remove">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      name: '',
      modal: false,
    };
  },
  mounted() {
    this.$store.dispatch('getAccountMembersUser').finally(() => {
      this.load = false;
    });
    this.$store.dispatch('getMemberRoles');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Account member has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'Account member has been delete', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async upadate() {
      const data = new Object();
      data.role = this.data.role;
      await this.$store.dispatch('updateAccountMembers', data).then(() => {
        this.notifi('update');
      });
    },
    async remove() {
      await this.$store.dispatch('deleteAccountMembers').then(() => {
        this.notifi('delete');
        this.modal = false;
        this.$router.push(`/accounts/${this.$route.params.accountId}`);
      });
    },
  },
  watch: {
    data() {
      this.name = `${this.data.user.first_name} ${this.data.user.last_name}`;
    },
  },
  computed: {
    data() {
      return this.$store.getters.membersUser;
    },
    roleList() {
      return this.$store.getters.memberRoleList;
    },
  },
  destroyed() {
    this.$store.dispatch('setAccountMembersUser', { user: {} });
  },
};
</script>
